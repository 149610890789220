import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { GetProductsResponse, GetProductsPayload } from 'store/api/types'
import { NextApiResponseBody } from 'types/boltApi/request'
import { CmsJobOffer, CmsProduct } from 'types/cms/collection-types'

export const cmsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/cms'
  }),
  endpoints: (builder) => ({
    getJobOffers: builder.query<CmsJobOffer[], void>({
      query: () => ({
        method: 'GET',
        url: '/job-offers?locale=all'
      }),
      transformResponse: ({ data }: NextApiResponseBody<CmsJobOffer[]>) => data
    }),

    getCmsProducts: builder.query<GetProductsResponse, GetProductsPayload>({
      query: ({ language }) => ({
        method: 'GET',
        url: `products?locale=${language}`
      }),
      transformResponse: ({ data }: NextApiResponseBody<CmsProduct[]>) => {
        return data.reduce((acc, product) => {
          acc[product.attributes.apiProduct.data.attributes.slug] = product
          return acc
        }, {} as GetProductsResponse)
      }
    })
  }),
  reducerPath: 'cmsApi'
})

export const { useGetJobOffersQuery, useGetCmsProductsQuery } = cmsApi
