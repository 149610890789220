import { MeterUsage } from 'features/simulation/types'
import { Language } from 'types/language'
import { Address, AddressFields } from 'components/form-fields/address-section/types'

// ENUMS

export enum EnergyType {
  BIO = 'Bio Energy',
  WATER = 'Water',
  WIND = 'Wind',
  ZON = 'Solar'
}

export enum State {
  AVAILABLE = 'Available',
  SOLD_OUT = 'Sold Out',
  RESERVED = 'Reserved'
}

// TYPES

export type CapacityState = {
  soldOut: boolean
  almostSoldOut: boolean
  usedEnergy: number
}

type Coordinates = {
  lat: number
  long: number
}

export type FetchProducerBySlugQuery = {
  full?: boolean
  withExtraImages?: boolean
}

export type FetchProducersQuery = {
  language: Language
  params?: {
    enabled?: boolean
    featured?: boolean
    full?: boolean
    limit?: number
    offset?: number
    popular?: boolean
    soldOut?: boolean
    withExtraImages?: boolean
  }
}

type Image = {
  url?: string
  name?: string
}

export type ImageObject = {
  extra?: Image[]
  header?: Image
  logo?: Image
  profile?: Image
}

type Locales<T> = {
  nl: T
  fr: T
}

export type Location = {
  lat: number
  lng: number
}

export type ParentProducer = ProducerBase & {
  locations: ProducerLocation[]
}

export type PersonalizedProducersOffer = {
  popular?: string
  recent?: string
  nearest?: string
}

export type PersonalizedProducersSimulationData = {
  address: Pick<Address, AddressFields.POSTAL_CODE | AddressFields.TOWN_CODE | AddressFields.TOWN_NAME>
  meterUsage: MeterUsage
  didSimulation: boolean
}

export type Producer = ProducerBase &
  ProducerLocation & {
    energyRegion: string
    isFeatured: Locales<boolean>
    isPopular: Locales<boolean>
    order: number
    parentSlug: string
    salesforceId: string
  }

export type ProducerBase = {
  id: string
  companyDescription: Locales<string>
  description: Locales<string>
  enabled: boolean
  facts?: Locales<string[]>
  images: ImageObject
  longDescription?: Locales<string>
  name: Locales<string>
  slug: string
}

export type ProducerLocation = {
  coordinates: Coordinates
  currentCapacity: number
  energyType: EnergyType
  energyUsed: number
  location: Locales<string>
  maxCapacity: number
  minOccupation: number
  slug: string
  state: State
}

export type ProspectAndCustomerProducer = {
  id: string
  name: Producer['name']
  salesforceId: string
  slug: Producer['slug']
  sourceRegion: string
}

export type FilterSortProducers = {
  producers: Producer[]
  language: Language
  hideSoldOut?: boolean
  capacity?: number
}
