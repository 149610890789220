import { store } from 'store'
import { ABTestingParams, AllAssignedExperiments, CommonTrackingEvents, ExperimentVariants } from 'types/tracking'
import { AB_TESTING_EVENTS, ALL_EXPERIMENTS } from 'constants/tracking'
import { setAllExperiments } from 'store/app/slice'
import mixpanel from 'mixpanel-browser'
import type { EventTrackingParameters } from 'types/event-tracking-parameters'

/**
 * Get locally stored testing group or get a random testing group and store it in local storage
 * @returns {AllAssignedExperiments}
 */
export const initAbTesting = (disableTesting: boolean): AllAssignedExperiments | undefined => {
  if (disableTesting) return undefined

  // If no experiment is active, remove all stored testing groups and experiments
  if (!Object.values(ALL_EXPERIMENTS).some((experiment) => experiment.active)) {
    Object.keys(localStorage).forEach((storageKey) => {
      // TODO: Remove 'testingGroup_' after go-live due to name change to experiment_
      if (['experiment_', 'testingGroup_'].some((prefix) => storageKey.startsWith(prefix))) {
        localStorage.removeItem(storageKey)
      }
    })

    return undefined
  }

  const assignedVariants = {} as AllAssignedExperiments

  Object.keys(ALL_EXPERIMENTS).forEach((experiment) => {
    // If the experiment is not active, remove the stored variant from the local storage
    if (!ALL_EXPERIMENTS[experiment].active) {
      localStorage.removeItem(`experiment_${experiment}`)
    } else {
      // Else, assign a variant to the experiment if it's not already assigned
      const currentExperimentVariants = ALL_EXPERIMENTS[experiment as unknown as keyof typeof ALL_EXPERIMENTS].variants
      const getRandomVariant = (): ExperimentVariants =>
        currentExperimentVariants[Math.floor(Math.random() * currentExperimentVariants.length)]
      const storedVariant = localStorage.getItem(`experiment_${experiment}`) as ExperimentVariants | null
      const validStoredVariant = storedVariant && currentExperimentVariants.includes(storedVariant)

      // Update storedVariant if it's not a valid variant
      const variant = validStoredVariant ? storedVariant : getRandomVariant()
      if (!validStoredVariant) localStorage.setItem(`experiment_${experiment}`, variant)

      // Start mixpanel AB testing
      mixpanel.track(AB_TESTING_EVENTS.START, {
        [ABTestingParams.EXPERIMENT]: experiment,
        [ABTestingParams.VARIANT]: variant
      })

      // Store the assigned variant in the assignedVariants object
      assignedVariants[experiment] = variant
    }
  })

  // Store the assigned variants in the store
  store.dispatch(setAllExperiments({ experiments: assignedVariants }))

  return assignedVariants
}

/**
 * Tracks events by pushing data to GTM
 *
 * @param {CommonTrackingEvents | string} event
 * @param {EventTrackingParameters} parameters
 */
export const trackEvent = (event: CommonTrackingEvents | string, parameters?: EventTrackingParameters): void => {
  if ((window as any).dataLayer) {
    ;(window as any).dataLayer.push({ event, ...parameters })
  }
}

/**
 * Resets the data layer
 */
export const resetDataLayer = (): void => {
  if ((window as any).dataLayer) {
    ;(window as any).dataLayer.push(() => {
      ;(window as any).dataLayer.reset()
    })
  }
}
