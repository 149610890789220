/**
 * Convert amount of solar panels to kVA
 * @param {number} amount
 * @returns {number}
 */
export const convertAmountOfSolarPanelsToKva = (amount: number): number => {
  const averageSolarPanelWattage = 245 // Average solar panel wattage
  const averageSolarPanelEfficiency = 0.85 // Solar panels don't convert all incoming sunlight into electricity perfectly
  const conversionFactor = 0.001 // 1kW = 1000W
  return (amount || 0) * averageSolarPanelWattage * averageSolarPanelEfficiency * conversionFactor
}

/**
 * Convert kVA to kWh
 * @param {number} kva
 * @returns {number}
 */
export const convertKvaToInjection = (kva: number): number => {
  const solarHoursPerYear = 850
  const injectedAmount = 0.66 // 66% of the kVA is injected or 33% self consumption
  return kva * solarHoursPerYear * injectedAmount
}
