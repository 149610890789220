import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { getStartDateRange } from 'features/registration/utils'
import Router from 'next/router'
import { RootState, store } from 'store'
import { updateMarketingData } from 'store/marketing/slice'
import { MarketingDataKeys, SimulationType } from 'store/marketing/types'
import { Language } from 'types/language'
import { MarketingParams } from 'types/marketing-params'
import { Producer, State } from 'types/producer'
import { getCapacityState, getProducersApiHelper } from 'utils/producers'
import { routes } from './routes'
import { AllProducts, ELProduct, NGProduct, Product, ProductData } from 'types/product-data'
import { checkHasInjectionUsageFields, checkHasSolarKva, requestAndSavePropositions } from 'features/simulation/utils'
import mixpanel from 'mixpanel-browser'
import {
  goToStep,
  selectProposition,
  setDidSimulation,
  setProductForSpecificSimulation,
  updateAllInputs,
  updateBoltGoPrices,
  updateFixedPrices,
  updateInput,
  updateSimulatedPropositions
} from 'store/customer/slice'
import { CounterTypes, MeterType, MeterUsage, SimulationSteps } from 'features/simulation/types'
import { convertKvaToInjection } from 'utils/conversion'
import { CustomerFlows, InputGroupKeys, PersonalDataFieldKeys, SimulationFieldKeys } from 'store/customer/enums'
import { RegistrationSteps, Situation } from 'features/registration/types'
import { BOLT_GO_PRICES } from 'constants/customerFlow'
import { CommonTrackingEvents, CommonTrackingParams, HomeTrackingParams } from 'types/tracking'
import { SIMULATION_TRACKING } from 'constants/tracking'
import { StartSimulationOrRegistrationParams } from 'types/simulation'
import { DEFAULT_PRODUCT } from 'constants/products'
import { trackEvent } from 'utils/tracking'
import { log } from 'utils/logging'
import { Flow } from 'types/logging'
import { EnergyGardens } from 'features/energy-gardens/types'
import { removeSpacesAndDots } from 'utils/helpers'
import axios from 'axios'
import { CheckPromoReferralCodeResponse, GetDataByOpportunityIdResponse, NextApiResponseBody } from 'types/boltApi/request'
import { CodeTypes } from 'types/promo-referral-codes'
import { StageName } from 'types/customer'

dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Fetches the data for the given opportunity id & push it into the store
 *
 * @param {Language} locale
 */
export const getDataByOpportunityId = async (locale: Language): Promise<void> => {
  const params = new URLSearchParams(location.search)

  if (params.has('opportunityId')) {
    // Destructure some store values
    const {
      customer: {
        inputs: { meterDetails, overview, simulation }
      },
      marketing: { emailConfirmationFlow }
    } = store.getState() as RootState

    // Clear window session storage if it exists
    if (window.sessionStorage) window.sessionStorage.clear()

    // Fetch the no EAN data
    const opportunityId = params.get('opportunityId') ?? ''
    const {
      data: { data: opportunityIdDataRes }
    } = await axios.get<NextApiResponseBody<GetDataByOpportunityIdResponse>>(`/api/prospects/${opportunityId}`)
    const prospect = opportunityIdDataRes?.prospect

    if (opportunityId) {
      mixpanel.identify(opportunityId)
    }

    if (!prospect) {
      log({ error: new Error(`No data found for opportunityId: ${opportunityId}`), identifier: `[${Flow.COMMON}:getDataByOpportunityId]` })
      // TODO: Should this redirect to a error page or pop up a snackbar with a message that the fetch failed?
      return
    }

    // Remove whitespace from mobile phone number
    if (prospect.inputs[InputGroupKeys.PERSONAL_DATA][PersonalDataFieldKeys.MOBILE_PHONE]) {
      prospect.inputs[InputGroupKeys.PERSONAL_DATA][PersonalDataFieldKeys.MOBILE_PHONE] = removeSpacesAndDots(
        prospect.inputs[InputGroupKeys.PERSONAL_DATA][PersonalDataFieldKeys.MOBILE_PHONE]
      )
    }

    const { boltGoPrices, fixedPrices, forcedAmount, marketingData, producerSlug, inputs, product, pricelistUrls, stage } = prospect

    // Update the marketing store parameters
    if (marketingData.confirmationFromPortalRegistration)
      store.dispatch(
        updateMarketingData({
          key: MarketingDataKeys.CONFIRMATION_FROM_PORTAL_REGISTRATION,
          value: marketingData.confirmationFromPortalRegistration
        })
      )
    if (marketingData.simulationType)
      store.dispatch(
        updateMarketingData({
          key: MarketingDataKeys.SIMULATION_TYPE,
          value: [marketingData.simulationType]
        })
      )
    if (marketingData.sourceType)
      store.dispatch(updateMarketingData({ key: MarketingDataKeys.SOURCE_TYPE, value: marketingData.sourceType }))

    // Check if contract start date is still valid & change if necessary
    const { max, min, initial } = getStartDateRange(inputs.meterDetails.situation)

    if (!dayjs(inputs.meterDetails.contractStartDate).isBetween(min, max, 'day', '[]')) {
      inputs.meterDetails.contractStartDate = initial
    }

    // Update the bolt go prices if necessary
    if (boltGoPrices) store.dispatch(updateBoltGoPrices(boltGoPrices))

    // Update the fixed prices if necessary
    if (fixedPrices) store.dispatch(updateFixedPrices(fixedPrices))

    // Update the selected product
    const productType = getProductFromElectricityProduct(product)
    store.dispatch(selectProposition({ productType }))

    // Set the customer store inputs
    store.dispatch(
      updateAllInputs({
        inputs: {
          ...inputs,
          meterDetails: {
            ...inputs.meterDetails,
            contractStartDate: inputs.meterDetails.contractStartDate ?? meterDetails.contractStartDate
          },
          simulation: {
            ...simulation,
            ...inputs.simulation,
            ...(marketingData.simulationType && {
              chosenSimulationType: marketingData.simulationType
            })
          },
          overview
        }
      })
    )

    // Save the price list urls
    if (pricelistUrls) store.dispatch(updateMarketingData({ key: MarketingDataKeys.PRICELIST_URLS, value: pricelistUrls }))

    // Check if Fixed but no fixed prices
    const fixedWithoutPrices = marketingData.simulationType === SimulationType.FIXED_AMOUNT && fixedPrices.electricity === null

    // Check if not came through portal && (not EAV/Fixed || Fixed without prices)
    if (!marketingData.confirmationFromPortalRegistration && (!checkIsEavOrFixed(marketingData.simulationType) || fixedWithoutPrices)) {
      // Set simulationType to Pricing simulation if fixed without prices & no solar panels
      if (fixedWithoutPrices) {
        store.dispatch(
          updateInput({
            group: InputGroupKeys.SIMULATION,
            key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE,
            value: SimulationType.PRICING_SIMULATION
          })
        )

        // If the user has solar panels, solarKva and digital meter in the opportunityId, convert solarKva to injection
        if (inputs.meterDetails.hasSolarPanels && inputs.simulation.solarKva && inputs.meterDetails.counterType === CounterTypes.DIGITAL) {
          const needsKva = checkHasSolarKva({
            counterType: inputs.meterDetails.counterType,
            hasSolarPanels: inputs.meterDetails.hasSolarPanels
          })

          const needsInjection = checkHasInjectionUsageFields({
            counterType: inputs.meterDetails.counterType,
            hasSolarPanels: inputs.meterDetails.hasSolarPanels
          })

          // Update the usage when injection is expected
          if (needsInjection) {
            // convert solarKva to injection
            const injection = convertKvaToInjection(inputs.simulation.solarKva)
            const injectionDay = injection * 0.66
            store.dispatch(
              updateInput({
                group: InputGroupKeys.SIMULATION,
                key: SimulationFieldKeys.USAGE,
                value: {
                  ...inputs.simulation.usage,
                  electricity: {
                    ...inputs.simulation.usage.electricity,
                    injection:
                      inputs.simulation.meterType === MeterType.SINGLE_RATE
                        ? {
                            single: Math.round(injection)
                          }
                        : {
                            double: {
                              day: Math.round(injectionDay),
                              night: Math.round(injection - injectionDay)
                            }
                          }
                  }
                } as MeterUsage
              })
            )
          }

          // Remove solarKva if not needed
          if (!needsKva) {
            store.dispatch(
              updateInput({
                group: InputGroupKeys.SIMULATION,
                key: SimulationFieldKeys.SOLAR_KVA,
                value: null
              })
            )
          }
        }
      }

      // Request and save propositions
      requestAndSavePropositions({
        products: [productType]
      })

      // Set didSimulation to true
      store.dispatch(setDidSimulation())
    }

    // Check if redirect to meter details is necessary during confirmation flow
    // when there is a move for an analog meter in the past & no meterNumbers are given
    if (emailConfirmationFlow) {
      const { personalData, meterDetails } = inputs

      if (
        meterDetails.counterType === CounterTypes.ANALOG &&
        meterDetails.situation === Situation.MOVE &&
        dayjs(meterDetails.contractStartDate).isSameOrBefore(dayjs(), 'day') &&
        (!meterDetails.electricity.meterNumber || (personalData.needsGas && !meterDetails.gas.meterNumber))
      ) {
        // Set the registration step to overview (user only needs to confirm)
        store.dispatch(goToStep({ flow: CustomerFlows.REGISTRATION, step: RegistrationSteps.METER_DETAILS }))
      }
    }

    // Update the forced amount if necessary
    if (forcedAmount)
      store.dispatch(updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.FORCED_AMOUNT, value: forcedAmount }))

    // Redirect to the correct producer page
    redirectToCorrectProducerPage(stage, producerSlug, locale)
  }
}

/**
 * Returns the electricity product
 *
 * @param {AllProducts} allProducts
 * @param {Product} product
 * @returns {ProductData | undefined}
 */
export const getElectricityProduct = (allProducts: AllProducts, product?: Product): ProductData | undefined => {
  const {
    customer,
    marketing: { availableProducts }
  } = store.getState()
  const elProduct: ELProduct =
    ELProduct[(product || customer.propositions?.selectedProposition || availableProducts?.[0] || DEFAULT_PRODUCT) as Product]

  return allProducts?.electricity[elProduct]
}

/**
 * Returns the gas product
 *
 * @param {AllProducts} allProducts
 * @param {Product} product
 * @returns {ProductData | undefined}
 */
export const getGasProduct = (allProducts: AllProducts, product?: Product): ProductData | undefined => {
  const {
    customer,
    marketing: { availableProducts }
  } = store.getState() as RootState
  const ngProduct: NGProduct =
    NGProduct[(product || customer.propositions?.selectedProposition || availableProducts?.[0] || DEFAULT_PRODUCT) as Product]

  return allProducts?.gas[ngProduct]
}

/**
 * Returns the correct Product enum value for the given ELProduct enum value
 *
 * @param {ELProduct} product
 * @returns {Product}
 */
export const getProductFromElectricityProduct = (product: ELProduct): Product => {
  switch (product) {
    case ELProduct.EarlyBirdOn:
      return Product.EARLY_BIRD_ONLINE

    case ELProduct.EarlyBirdOff:
      return Product.EARLY_BIRD_OFFLINE

    case ELProduct.VariableOn:
      return Product.VARIABLE_ONLINE

    case ELProduct.VariableOff:
      return Product.VARIABLE_OFFLINE

    case ELProduct.Go:
      return Product.GO

    case ELProduct.Fix:
      return Product.FIX
  }
}

/**
 * Checks if the given simulation type is EAV or Fixed
 *
 * @param {SimulationType} simulationType
 * @returns {boolean}
 */
export const checkIsEavOrFixed = (simulationType: SimulationType): boolean => {
  return [SimulationType.FIXED_AMOUNT, SimulationType.EAV].includes(simulationType)
}

/**
 * Redirects to the correct producer page
 *
 * @param {StageName} stage
 * @param {string} producerSlug
 * @param {Language} locale
 * @returns {Promise<void>}
 */
const redirectToCorrectProducerPage = async (stage: StageName, producerSlug: string, locale: Language) => {
  // Get values from the store
  const { selectedProducer } = (store.getState() as RootState).app
  const { flows, inputs } = (store.getState() as RootState).customer

  if (stage === StageName.CLOSED_WON) {
    await Router.push({ pathname: routes(locale, producerSlug).registration, query: { isClosedWon: true } })
    return
  }

  if (!producerSlug && !selectedProducer) {
    const producers = await getProducersApiHelper({ language: locale, params: { enabled: true, soldOut: false } })
    producerSlug = producers[0].slug
  }

  // Check if the producerSlug is empty & if so, set it to the selectedProducer
  if (!producerSlug) producerSlug = selectedProducer

  // Set the producerSlug in the window session storage
  window.sessionStorage.setItem('producerSlug', producerSlug)

  // Check if producer is not sold out (take simulation data into account)
  // If so, navigate to another producer wich is not soldOut and also not almostSoldOut
  // If not, navigate to registration page of current producer
  const {
    data: { data: producer }
  } = await axios.get<NextApiResponseBody<Producer>>(`/api/producers/${producerSlug}`)
  const capacityState = getCapacityState(producer, inputs.simulation, flows.simulation.didSimulation)
  const { soldOut } = capacityState

  if (soldOut) {
    const producers = await getProducersApiHelper({ language: locale, params: { enabled: true } })
    const availableProducer = producers.filter((producer: Producer) => {
      const capacityState = getCapacityState(producer, inputs.simulation, flows.simulation.didSimulation)
      const { soldOut, almostSoldOut } = capacityState

      if (!soldOut && !almostSoldOut && producer.state !== State.RESERVED) {
        return producer
      }
    })[0]

    if (availableProducer.slug) {
      await Router.push(routes(locale, availableProducer.slug).registration)
    } else {
      await Router.push(routes(locale).producers)
    }
  } else {
    await Router.push(routes(locale, producerSlug).registration)
  }
}

/**
 * Sets marketing params in Redux store
 *
 * @param {MarketingParams} marketingParams
 */
export const setMarketingParamsInStore = (marketingParams: MarketingParams): void => {
  const { sourceType, simulationSo } = marketingParams

  if (sourceType) store.dispatch(updateMarketingData({ key: MarketingDataKeys.SOURCE_TYPE, value: sourceType }))
  if (simulationSo) store.dispatch(updateMarketingData({ key: MarketingDataKeys.SIMULATION_SO, value: simulationSo }))
}

/**
 * Fetches a promo code, sets it in the store & returns the code and whether it's valid or not
 *
 * @param {string} code
 */
export const setPromoOrReferralCodeInStore = async (code: string): Promise<{ code: string; valid: boolean }> => {
  const {
    data: { data: checkedPromoReferralCode }
  } = await axios.get<NextApiResponseBody<CheckPromoReferralCodeResponse>>(`/api/website/promo-referral-code?code=${code}`)

  const isPromoCode = checkedPromoReferralCode.type === CodeTypes.PROMO

  if (checkedPromoReferralCode.valid) {
    store.dispatch(
      updateInput({
        group: InputGroupKeys.SIMULATION,
        ...(isPromoCode
          ? {
              key: SimulationFieldKeys.PROMO_CODE,
              value: checkedPromoReferralCode.promoCode
            }
          : {
              key: SimulationFieldKeys.REFERRAL_CODE,
              value: checkedPromoReferralCode.referralCode
            })
      })
    )
  }

  return {
    code: isPromoCode ? checkedPromoReferralCode.promoCode.code : checkedPromoReferralCode.referralCode.code,
    valid: checkedPromoReferralCode.valid
  }
}

/**
 * Opens the simulation page
 * optional: pass the simulationStep to go to a specific step
 * @param {SimulationSteps} from
 */
const openSimulation = (from?: SimulationSteps) => {
  if (from) store.dispatch(goToStep({ flow: CustomerFlows.SIMULATION, step: from }))

  return Router.push({ pathname: routes(Router.locale || Language.DUTCH).simulation })
}

/**
 * Start simulation or registration based on store
 * @param {StartSimulationOrRegistrationParams} props
 */
export const startSimulationOrRegistration = async ({
  ctaTrackingVariant,
  simulationSalesOffice,
  specificProduct,
  startFromUsage
}: StartSimulationOrRegistrationParams) => {
  // Redux store
  const { selectedProposition, simulatedPropositions } = store.getState().customer.propositions
  const { didSimulation, currentStep } = store.getState().customer.flows.simulation
  const { promoCode, chosenSimulationType } = store.getState().customer.inputs[InputGroupKeys.SIMULATION]
  const { selectedProducer } = store.getState().app
  const { availableProducts, campaignName } = store.getState().marketing

  // Constants
  const isEavOrFixed = checkIsEavOrFixed(chosenSimulationType)
  const route = Router.route

  // Set the simulation sales office if passed
  if (simulationSalesOffice) {
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.SIMULATION_SO, value: simulationSalesOffice }))
  }

  // Clear the promo code & campaignName if the user is coming from the Brugse Zonnetuin campaign
  // and starting a simulation/registration from elsewhere
  if (campaignName === EnergyGardens.BRUGSE_ZONNETUIN && promoCode) {
    store.dispatch(updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.PROMO_CODE, value: null }))
    store.dispatch(updateMarketingData({ key: MarketingDataKeys.CAMPAIGN_NAME, value: null }))
  }

  // Track simulation cta click
  mixpanel.track(SIMULATION_TRACKING.CLICK_SIMULATION_CTA, {
    [CommonTrackingParams.VARIANT]: ctaTrackingVariant,
    [HomeTrackingParams.DONE_SIM]: didSimulation,
    [HomeTrackingParams.FROM]: startFromUsage ? SimulationSteps.USAGE : currentStep,
    [CommonTrackingParams.FROM_PAGE]: route === '/' ? 'home' : route,
    [CommonTrackingParams.SELECTED_PRODUCT]: specificProduct
  })

  // Start the Bolt Go registration flow
  if (specificProduct === Product.GO) {
    const { needsGas } = store.getState().customer.inputs.personalData
    // Clear the propositions in the store
    store.dispatch(updateSimulatedPropositions({ propositions: null }))
    // Select the GO product
    store.dispatch(selectProposition({ productType: specificProduct }))
    // Set the simulation type
    store.dispatch(
      updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE, value: SimulationType.BOLT_GO })
    )
    // Set the bolt go prices if necessary
    store.dispatch(updateBoltGoPrices({ electricity: BOLT_GO_PRICES.electricity, gas: needsGas ? BOLT_GO_PRICES.gas : 0 }))
    // Start registration
    return Router.push(routes(Router.locale).registrationLoading)
  }

  // Go straight to registration if the its a EAV or Fixed flow
  if (isEavOrFixed) {
    // select the default product
    store.dispatch(selectProposition({ productType: availableProducts?.[0] || DEFAULT_PRODUCT }))
    return Router.push(routes(Router.locale, selectedProducer).registration)
  }

  // Fetch propositions if the user has already done a simulation and the propositions for this product have not been fetched yet
  if (didSimulation && specificProduct && simulatedPropositions && !simulatedPropositions?.[specificProduct]) {
    requestAndSavePropositions({
      products: [specificProduct]
    })
  }

  // Go straight to registration if the user has already done a simulation and the selected proposition is the same as the specific product
  if (didSimulation && selectedProposition && selectedProposition === specificProduct) {
    return Router.push(selectedProducer ? routes(Router.locale, selectedProducer).registration : routes(Router.locale).producers)
  }

  // Set or reset specific product for simulation if available
  if (specificProduct) {
    store.dispatch(setProductForSpecificSimulation(specificProduct))
  } else {
    store.dispatch(setProductForSpecificSimulation(null))
  }

  // Track 'Simulation Started' event @Semetis
  trackEvent(CommonTrackingEvents.SIMULATION_STARTED)

  return openSimulation(
    // Go to usage step if there is a postal code & the current step is not PostalCodeStep or EnergyTypeStep
    startFromUsage ? SimulationSteps.USAGE : undefined
  )
}
