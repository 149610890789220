import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialEnergyGardenState } from 'store/energy-gardens/initial'
import type { SetBankTransferDetailsPayload, SetCurrentViewPayload, UpdateEnergyGardenInputsPayload } from 'store/energy-gardens/types'

export const EnergyGardenSlice = createSlice({
  initialState: initialEnergyGardenState,
  name: 'energyGardens',
  reducers: {
    setBankTransferDetails: (state, action: PayloadAction<SetBankTransferDetailsPayload>) => {
      const { garden, ...bankTransferDetails } = action.payload
      state[garden].bankTransferDetails = bankTransferDetails
    },
    setCurrentEnergyGardenView: (state, action: PayloadAction<SetCurrentViewPayload>) => {
      const { garden, view } = action.payload
      state[garden].currentView = view
    },
    updateEnergyGardenInput: (state, action: PayloadAction<UpdateEnergyGardenInputsPayload>) => {
      const { garden, inputs } = action.payload
      state[garden].inputs = { ...state[garden].inputs, ...inputs }
    }
  }
})

export const { setBankTransferDetails, setCurrentEnergyGardenView, updateEnergyGardenInput } = EnergyGardenSlice.actions

export default EnergyGardenSlice.reducer
