import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { Region } from 'types/region'

// ENUMS

export enum AddressFields {
  STREET = 'streetName',
  NUMBER = 'streetNumber',
  BOX = 'streetBox',
  POSTAL_CODE = 'postalCode',
  TOWN_NAME = 'townName',
  TOWN_CODE = 'townCode'
}

export enum AddressFormTypes {
  CORRESPONDENCE = 'correspondenceAddress',
  DELIVERY = 'deliveryAddress',
  COMPANY_ADRESS = 'companyAddress',
  INSTALLATION_ADDRESS = 'installationAddress',
  ADDRESS = 'address'
}

// TYPES

export type Address = {
  [AddressFields.STREET]: string
  [AddressFields.NUMBER]: string
  [AddressFields.BOX]: string
  [AddressFields.POSTAL_CODE]: string
  [AddressFields.TOWN_NAME]: string
  [AddressFields.TOWN_CODE]: number
}

export type AddressFormProps = {
  className?: string
  errors: any // 'errors' from useForm hook (React Hook Form)
  input: Address
  isNested?: boolean
  productAvailabillityRegions?: Region[]
  productName?: string
  register: UseFormRegister<any> // 'register' from useForm hook (React Hook Form)
  setValue: UseFormSetValue<any> // 'setValue' from useForm hook (React Hook Form)
  type: string
  required?: boolean
}
