import { ClientType } from './customer'

export enum EnergyUsageGrade {
  LOW = 'Low',
  AVERAGE = 'Average',
  HIGH = 'High',
  CUSTOM = 'Custom'
}

export type EstimatedUsages = {
  [key in EnergyUsageGrade.LOW | EnergyUsageGrade.AVERAGE | EnergyUsageGrade.HIGH]: {
    [key in ClientType]: EstimatedUsage
  }
}

export type EstimatedUsage = {
  electricity: {
    consumption: number
    injection: number
    kva: number
  }
  gas: number
}
