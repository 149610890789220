import { EnergyGardenOrderInputs, EnergyGardenOrderViews, type EnergyGardenStore } from 'store/energy-gardens/types'
import { EnergyGardens } from 'features/energy-gardens/types'
import { AddressFields } from 'components/form-fields/address-section/types'

export const initialEnergyGardenState: EnergyGardenStore = (Object.keys(EnergyGardens) as Array<keyof EnergyGardens>).reduce(
  (acc, garden) => {
    acc[EnergyGardens[garden]] = {
      currentView: EnergyGardenOrderViews.POSTAL_CODE,
      inputs: {
        contactData: {
          acceptedTerms: false,
          address: {
            [AddressFields.STREET]: '',
            [AddressFields.NUMBER]: '',
            [AddressFields.POSTAL_CODE]: '',
            [AddressFields.TOWN_CODE]: 0,
            [AddressFields.TOWN_NAME]: '',
            [AddressFields.BOX]: null
          },
          email: '',
          firstName: '',
          lastName: '',
          mobilePhone: '',
          mobilePhoneCountryCode: 'BE',
          repeatEmail: ''
        },
        isAlreadyCustomer: false,
        products: undefined,
        skipCustomer: false
      } satisfies EnergyGardenOrderInputs
    }
    return acc
  },
  {} as EnergyGardenStore
)
