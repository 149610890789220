import customerReducer from 'store/customer/slice'
import appReducer from 'store/app/slice'
import marketingReducer from 'store/marketing/slice'
import energyGardenReducer from 'store/energy-gardens/slice'
import { boltApi } from 'store/api/boltApi'
import { cmsApi } from 'store/api/cmsApi'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

const rootReducer = combineReducers({
  app: appReducer,
  customer: customerReducer,
  energyGardens: energyGardenReducer,
  marketing: marketingReducer,
  [boltApi.reducerPath]: boltApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer
})

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([boltApi.middleware, cmsApi.middleware]),
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production'
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
