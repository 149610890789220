import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { ProducerDictionary } from 'store/app/types'
import { PersonalizedProducersSimulationData, Producer } from 'types/producer'
import { isSoldOutWithCapacity } from 'utils/producers'
import { Language } from 'types/language'
import { PersonalizedProducersOffers } from 'store/customer/types'
import { AllProducts } from 'types/product-data'
import { GetPersonalizedProducersResponse, NextApiResponseBody } from 'types/boltApi/request'
import { EnergyGarden } from 'features/energy-gardens/types'

export const boltApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getEnergyGardenBySlug: builder.query<EnergyGarden | null, string>({
      query: (slug) => ({
        method: 'GET',
        url: `/energy-gardens/${slug}`
      }),
      transformResponse: ({ success, data }: NextApiResponseBody<EnergyGarden>) => {
        return success && data ? data : null
      }
    }),

    getPersonalizedOffer: builder.query<PersonalizedProducersOffers, PersonalizedProducersSimulationData>({
      query: (simulationData) => ({
        body: {
          expectedProducers: { nearest: true, popular: true, recent: true },
          ...simulationData
        },
        method: 'POST',
        url: '/producers/personalized'
      }),
      transformResponse: ({ data }: NextApiResponseBody<GetPersonalizedProducersResponse>): PersonalizedProducersOffers => {
        const { popular: popularNL, nearest: nearestNL, recent: recentNL } = data[Language.DUTCH]
        const { popular: popularFR, nearest: nearestFR, recent: recentFR } = data[Language.FRENCH]

        return {
          [Language.DUTCH]: { nearest: nearestNL?.slug, popular: popularNL?.slug, recent: recentNL?.slug },
          [Language.FRENCH]: { nearest: nearestFR?.slug, popular: popularFR?.slug, recent: recentFR?.slug }
        }
      }
    }),

    getProducers: builder.query<ProducerDictionary, void>({
      query: () => ({
        method: 'GET',
        url: '/producers?enabled=true'
      }),
      transformResponse: ({ success, data }: NextApiResponseBody<Producer[]>): ProducerDictionary => {
        if (success && data.length) {
          const filteredProducers = data.filter((p: Producer) => !isSoldOutWithCapacity(p))
          return filteredProducers.reduce((acc: ProducerDictionary, producer: Producer) => {
            acc[producer.slug] = producer
            return acc
          }, {})
        }
      }
    }),

    getProducts: builder.query<AllProducts | null, void>({
      query: () => ({
        method: 'GET',
        url: '/products'
      }),
      transformResponse: ({ success, data }: NextApiResponseBody<AllProducts>): AllProducts | null => {
        return success && data ? data : null
      }
    })
  }),
  reducerPath: 'boltApi'
})

export const { useGetEnergyGardenBySlugQuery, useGetPersonalizedOfferQuery, useGetProducersQuery, useGetProductsQuery } = boltApi
