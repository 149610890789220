import { CookieKeys } from 'types/cookies'

/**
 * Creates a cookie
 *
 * @param name
 * @param value
 * @param days
 */
export const createCookie = (name: CookieKeys, value: string, days = 60): void => {
  if (typeof window === 'undefined') return // do nothing if running on server

  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)

  const expires = `; expires=${date.toUTCString()}`

  document.cookie = `${name}=${value}${expires}; path=/`
}

/**
 * Reads & returns a cookie
 *
 * @param name
 * @returns string|null
 */
export const readCookie = (name: CookieKeys): string | null => {
  if (typeof window === 'undefined') return // do nothing if running on server

  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.startsWith(' ')) c = c.substring(1, c.length)

    if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length)
  }

  return null
}
