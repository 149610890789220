import { SimulatePriceResponse } from 'features/simulation/types'
import { RootState, store } from 'store'
import { Proposition, SimulatedPropositions } from 'store/customer/types'
import { SimulationType } from 'store/marketing/types'
import { Product } from 'types/product-data'
import { DEFAULT_PRODUCT } from 'constants/products'
import { InputGroupKeys, SimulationFieldKeys } from 'store/customer/enums'

/**
 * Returns the product to select
 *
 * @param {Product} product
 * @returns {Product | undefined}
 */
export const getProductToSelect = (product?: Product): Product | undefined => {
  if (!store) return undefined

  // Fetch data from the store
  const { selectedProposition } = (store.getState() as RootState).customer.propositions
  const { availableProducts } = (store.getState() as RootState).marketing

  // Define the product to select & return it
  return product || selectedProposition || availableProducts?.[0] || DEFAULT_PRODUCT
}

/**
 * Returns the selected proposition if available, otherwise the first proposition
 *
 * @param {Product} product
 * @param {SimulatedPropositions} propositionsData
 * @returns {Proposition | undefined}
 */
export const getProposition = (product?: Product, propositionsData?: SimulatedPropositions): Proposition | undefined => {
  if (!store) return undefined

  // Fetch data from the store
  const { propositions } = store.getState().customer

  // Define the propositions & product to select
  const propositionsToUse = propositionsData || propositions?.simulatedPropositions
  const productToSelect = getProductToSelect(product)

  // Check if propositions are available
  if (propositionsToUse && Object.keys(propositionsToUse).length > 0) {
    // Return the selected proposition if available, otherwise the first proposition
    return productToSelect ? propositionsToUse[productToSelect] : Object.values(propositionsToUse)[0]
  }

  return undefined
}

/**
 * Returns the proposition price if available
 *
 * @param {Product} product
 * @param {SimulatedPropositions} propositions
 * @returns {SimulatePriceResponse['price'] | undefined}
 */
export const getSimulatedPrice = (product?: Product, propositions?: SimulatedPropositions): SimulatePriceResponse['price'] | undefined => {
  const proposition = getProposition(product, propositions)

  if (proposition) return proposition.price

  return undefined
}

/**
 * Returns the total simulated price
 *
 * @param {SimulatePriceResponse['price]} customPrice
 * @param {SimulatedPropositions} propositions
 * @returns {number | undefined}
 */
export const getTotalSimulatedPrice = (
  customPrice?: SimulatePriceResponse['price'],
  propositions?: SimulatedPropositions
): number | undefined => {
  if (!store) return undefined

  const { fixedPrices, inputs } = (store.getState() as RootState).customer

  // FIXED PRICES
  if (inputs.simulation.chosenSimulationType === SimulationType.FIXED_AMOUNT && fixedPrices) {
    return Number(fixedPrices.electricity) + Number(fixedPrices.gas)
  }

  // SIMULATED PRICES
  const price = customPrice ?? getSimulatedPrice(undefined, propositions)
  if (!price) return undefined

  // Determine the price to use based on res/pro
  const priceToUse = price.total[inputs.personalData.isCompany ? 'totalWithoutVAT' : 'total']

  // Subtract the yearly energy garden promo amount if available & return it
  if (inputs[InputGroupKeys.SIMULATION][SimulationFieldKeys.ENERGY_GARDEN_YEARLY_PROMO_AMOUNT]) {
    return priceToUse - inputs[InputGroupKeys.SIMULATION][SimulationFieldKeys.ENERGY_GARDEN_YEARLY_PROMO_AMOUNT] / 12
  }

  // Return the price to use
  return priceToUse
}
