import Bugsnag from '@bugsnag/js'
import { Criticality, type IdentifierFormats, type Logging, Severity } from 'types/logging'
import { BUGSNAG_API_KEY, IS_PROD, IS_STAGING } from 'constants/envs'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export const initializeBugsnag = () => {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: IS_PROD ? 'production' : IS_STAGING ? 'staging' : 'local',
    enabledReleaseStages: ['production'] // Only send errors in production (add 'staging' to send in dev/staging),
  })
}

/**
 * Logs the error and metadata to Bugsnag
 * @param {Logging} paramsObject
 */
export const log = <T extends IdentifierFormats>({
  error,
  metaData,
  identifier,
  severity = Severity.ERROR,
  criticality = Criticality.MEDIUM
}: Logging<T>) => {
  const err = typeof error === 'string' ? new Error(error) : error

  if (!Bugsnag.isStarted()) initializeBugsnag()

  Bugsnag.notify(err, (event) => {
    if (metaData) Object.keys(metaData).forEach((section) => event.addMetadata(section, metaData[section]))
    event.context = `[${criticality.toUpperCase()}] - ${identifier}`
    event.severity = severity
  })
}
