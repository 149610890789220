/**
 * Formats a number to the nl-BE currency notation with the optional max given fraction digits (default 2)
 *
 * @param {number} num
 * @param {number} maximumFractionDigits
 * @returns {string}
 */
export const formatAmount = (num: number, maximumFractionDigits?: number): string => {
  return num?.toLocaleString('nl-BE', { maximumFractionDigits: maximumFractionDigits ?? 2 })
}

/**
 * Removes all special characters and converts to lowercase
 *
 * @param {string} text
 * @returns {string}
 */
export const normalizeText = (text: string): string => {
  return text
    ? text
        .normalize('NFD') // separate accents from letters and return Unicode codes
        .replace(/[\u0300-\u036f]/g, '') // replace diacritical characters with empty string
        .toLowerCase()
    : ''
}
