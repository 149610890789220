import { ALL_EXPERIMENTS } from 'constants/tracking'

export type AllExperiments = {
  [key in AB_EXPERIMENTS]: {
    variants: string[]
    active: boolean
  }
}
export type ExperimentVariants = (typeof ALL_EXPERIMENTS)[AB_EXPERIMENTS]['variants'][number]
export type AllAssignedExperiments = {
  [key in AB_EXPERIMENTS]: ExperimentVariants
}

export enum AB_EXPERIMENTS {
  TRUSTPILOT_BANNER = 'trustpilotBanner'
}

export enum CommonTrackingEvents {
  NEWSLETTER_SUBSCRIBE = 'newsletter_subscribe',
  SIMULATION_STARTED = 'simulation_started'
}

export enum CommonTrackingParams {
  CAMPAIGN = 'campaign',
  CURRENT_PAGE = 'currentPage',
  CUSTOMER_TYPE = 'customerType',
  FLOW = 'flow',
  FROM_PAGE = 'fromPage',
  PORTAL_CONFIRMATION = 'portalConfirmation',
  SELECTED_PRODUCT = 'selectedProduct',
  SIMULATION_TYPE = 'simulationType',
  STEP = 'step',
  USAGE_TYPE = 'usageType',
  VARIANT = 'variant'
}

export enum EnergyGardenTrackingParams {
  ENERGY_GARDEN = 'energyGarden',
  PAYMENT_STATE = 'paymentState',
  PAYMENT_FAILED_MSG = 'paymentFailedMessage',
  ORDER_STATUS = 'orderStatus',
  PRODUCTS = 'products'
}

export enum HomeTrackingParams {
  DONE_SIM = 'hasDoneSimulation',
  FROM = 'startingFrom'
}

export enum ABTestingParams {
  EXPERIMENT = 'Experiment name',
  VARIANT = 'Variant name'
}

export enum TRUSTPILOT_BANNER_VARIANTS {
  CONTROL = 'Control',
  TRUSTPILOT = 'Trustpilot'
}
