export enum BillingFieldKeys {
  CORRESPONDENCE_ADDRESS = 'correspondenceAddress',
  SAME_CORRESPONDENCE_ADDRESS = 'sameCorrespondenceAddress',
  IBAN = 'iban',
  INVOICE_MEDIUM = 'invoiceMedium',
  PAYMENT_METHOD = 'paymentMethod'
}

export enum CustomerFlows {
  SIMULATION = 'simulation',
  REGISTRATION = 'registration'
}

export enum InputGroupKeys {
  BILLING = 'billing',
  METER_DETAILS = 'meterDetails',
  OVERVIEW = 'overview',
  PERSONAL_DATA = 'personalData',
  SIMULATION = 'simulation'
}

export enum MeterDetailsFieldKeys {
  CONTRACT_START_DATE = 'contractStartDate',
  COUNTER_TYPE = 'counterType',
  DYNAMIC_TARIFF = 'dynamicTariff',
  EAN_CAPTURE_TYPE = 'eanCaptureType',
  ELECTRICITY = 'electricity',
  GAS = 'gas',
  HAS_SOLAR_PANELS = 'hasSolarPanels',
  KNOWS_METER_DETAILS = 'knowsMeterDetails',
  METER_READING_MONTH = 'meterReadingMonth',
  PREVIOUS_SUPPLIER = 'previousSupplier',
  SITUATION = 'situation',
  WALLONIA_SOLAR_PANELS_SINCE_2024 = 'walloniaSolarPanelsSince2024',
  UPLOAD = 'upload',
  PREVIOUS_INHABITANT = 'previousInhabitant'
}

export enum OverviewFieldKeys {
  SIGNATURE = 'signature',
  CHOSEN_SIGN_CHANNEL = 'chosenSignChannel'
}

export enum PersonalDataFieldKeys {
  COMPANY_NAME = 'companyName',
  COMPANY_NUMBER = 'companyNumber',
  CONTACT_MOMENT = 'contactMoment',
  CREDIT_CHECK = 'creditCheck',
  DATE_OF_BIRTH = 'dateOfBirth',
  DELIVERY_ADDRESS = 'deliveryAddress',
  EMAIL = 'email',
  FIRSTNAME = 'firstName',
  IS_COMPANY = 'isCompany',
  LANGUAGE = 'language',
  LASTNAME = 'lastName',
  LEGAL_FORM = 'legalForm',
  MOBILE_PHONE = 'mobilePhone',
  MOBILE_PHONE_COUNTRY_CODE = 'mobilePhoneCountryCode',
  NEEDS_GAS = 'needsGas',
  REPEAT_EMAIL = 'repeatEmail',
  SUBJECT_TO_VAT = 'subjectToVat'
}

export enum SimulationFieldKeys {
  ENERGY_GARDEN_YEARLY_PROMO_AMOUNT = 'energyGardenYearlyPromoAmount',
  FORCED_AMOUNT = 'forcedAmount',
  HAS_EXCLUSIVE_NIGHT_METER = 'hasExclusiveNightMeter',
  METER_TYPE = 'meterType',
  PRICE_WITHOUT_PROMO = 'priceWithoutPromo',
  PROMO_CODE = 'promoCode',
  REFERRAL_CODE = 'referralCode',
  REGION = 'region',
  SOLAR_KVA = 'solarKva',
  SOLAR_PANELS = 'solarPanels',
  USAGE = 'usage',
  USAGE_GRADE = 'usageGrade',
  CHOSEN_SIMULATION_TYPE = 'chosenSimulationType'
}
