// ENUMS

export enum CodeTypes {
  PROMO = 'Promo',
  REFERRAL = 'Referral'
}

// DATA TYPES

export type PromoCode = {
  code: string
  refs: {
    resEL: string
    resNG: string
    proEL: string
    proNG: string
    contract: string
  }
  translations: {
    nl: string
    fr: string
  }
  promoCodeId: string
}

export type ReferralCode = {
  code: string
  referrer: {
    id: string // SF Contact id
    firstName: string // SF Contact first name
  }
}
