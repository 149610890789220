import { CustomerFlows } from './enums'
import { Flows, RegistrationFlow, SimulationFlow } from './types'

/**
 * Returns next step based on a given flow & current step
 *
 * @template T
 * @param {T[]} steps
 * @param {T} currentStep
 * @returns {T}
 */
export const getNextStep = <T>(steps: T[], currentStep: T): T => {
  const currentIndex = steps.findIndex((step: T) => step === currentStep)
  return currentIndex + 1 < steps.length ? steps[currentIndex + 1] : steps[currentIndex]
}

/**
 * Returns previous step based on a given flow & current step
 *
 * @template T
 * @param {T[]} steps
 * @param {T} currentStep
 * @returns {T}
 */
export const getPreviousStep = <T>(steps: T[], currentStep: T): T => {
  const currentIndex = steps.findIndex((step: T) => step === currentStep)
  return currentIndex - 1 >= 0 ? steps[currentIndex - 1] : steps[currentIndex]
}

/**
 * Returns the customer flows with the new values spreaded over the current values
 *
 * @param {Flows} flows
 * @param {CustomerFlows} flow
 * @param {Partial<RegistrationFlow> | Partial<SimulationFlow>} newValues
 * @returns {Flows}
 */
export const getSpreadedFlowsWithNewValues = (
  flows: Flows,
  flow: CustomerFlows,
  newValues: Partial<RegistrationFlow> | Partial<SimulationFlow>
): Flows => {
  return {
    ...flows,
    [flow]: {
      ...flows[flow],
      ...newValues
    }
  }
}
