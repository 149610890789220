import { Language } from 'types/language'
import { checkIsStaging } from 'utils/helpers'

export const FullLanguages = {
  [Language.DUTCH]: 'Nederlands',
  [Language.FRENCH]: 'Français'
}

export const PHONE_NOT_REACHABLE = false

export const PORTAL_URL = `${checkIsStaging() ? 'https://staging.' : 'https://'}my.boltenergie.be`

export enum SessionStorageKeys {
  MARKETING = 'MarketingStore'
}

export const socials = {
  email: 'mailto:info@boltenergie.be',
  facebook: 'https://www.facebook.com/boltenergie/',
  instagram: 'https://www.instagram.com/boltenergie/'
}

export const BOLT_ENERGIE_CARE_PARTNER_ID = '0010900001mIpTBAA0'
