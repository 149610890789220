/* eslint-disable sort-keys */
import { RouteVariables } from 'types/routes'

export const ROUTES = {
  ABOUT: {
    nl: 'onze-missie',
    fr: 'notre-mission'
  },
  BECOME_PRODUCER: {
    nl: 'opwekker-worden',
    fr: 'devenir-producteur'
  },
  BECOME_PRODUCER_REQUEST: {
    nl: 'opwekker-worden/aanvraag',
    fr: 'devenir-producteur/application'
  },
  BLOG: {
    nl: 'nieuws',
    fr: 'actus'
  },
  BLOG_ARTICLE: {
    nl: `nieuws/${RouteVariables.ARTICLE}`,
    fr: `actus/${RouteVariables.ARTICLE}`
  },
  BOLT_GO: {
    nl: 'bolt-go',
    fr: 'bolt-go'
  },
  BOLT_FIX: {
    nl: 'bolt-vast',
    fr: 'bolt-fixe'
  },
  CAREERS: {
    nl: 'vacatures',
    fr: 'jobs'
  },
  CAREER_DETAIL: {
    nl: `vacatures/${RouteVariables.SLUG}`,
    fr: `jobs/${RouteVariables.SLUG}`
  },
  DYNAMIC_PRICES: {
    nl: 'dynamische-prijzen',
    fr: 'prix-dynamiques'
  },
  ENCYCLOPEDIA: {
    nl: 'encyclopedie',
    fr: 'encyclopedie'
  },
  ENCYCLOPEDIA_ARTICLE: {
    nl: `encyclopedie/${RouteVariables.CATEGORY}/${RouteVariables.SUB_CATEGORY}/${RouteVariables.ARTICLE}`
    // fr: `encyclopedie/${RouteVariables.CATEGORY}/${RouteVariables.SUB_CATEGORY}/${RouteVariables.ARTICLE}`
  },
  ENCYCLOPEDIA_CATEGORY: {
    nl: `encyclopedie/${RouteVariables.CATEGORY}`
    // fr: `encyclopedie/${RouteVariables.CATEGORY}`
  },
  ENCYCLOPEDIA_SUB_CATEGORY: {
    nl: `encyclopedie/${RouteVariables.CATEGORY}/${RouteVariables.SUB_CATEGORY}`
    // fr: `encyclopedie/${RouteVariables.CATEGORY}/${RouteVariables.SUB_CATEGORY}`
  },
  ENERGY_GARDENS_OVERVIEW: {
    nl: 'energie-tuinen',
    fr: 'jardins-denergie'
  },
  ENERGY_GARDEN: {
    nl: `energie-tuinen/${RouteVariables.SLUG}`,
    fr: `jardins-denergie/${RouteVariables.SLUG}`
  },
  ENERGY_GARDEN_CONTACT: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/contact`
  },
  ENERGY_GARDEN_FAQ: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/veelgestelde-vragen`
  },
  ENERGY_GARDEN_HOW_IT_WORKS: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/hoe-werkt-het`
  },
  ENERGY_GARDEN_INFO_SESSION: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/infomoment`
  },
  ENERGY_GARDEN_TERMS: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/investering`
  },
  ENERGY_GARDEN_ORDER: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/bestel`
  },
  ENERGY_GARDEN_CONFIRMATION: {
    nl: `energie-tuinen/${RouteVariables.SLUG}/bevestiging`
  },
  REGISTRATION_LOADING: {
    nl: 'stap-over',
    fr: 'enregistrer-maintenant'
  },
  FAQ: {
    nl: 'veelgestelde-vragen',
    fr: 'questions-frequentes'
  },
  FESTIVALS: {
    nl: 'festivals',
    fr: 'festivals'
  },
  HONEST_ENERGY: {
    nl: 'eerlijke-energie'
    // no fr
  },
  MINI_GENERATORS: {
    nl: 'mini-opwekkers',
    fr: 'mini-producteurs'
  },
  MOVE: {
    nl: 'verhuizen',
    fr: 'demenager'
  },
  PERSONALIZED_OFFER: {
    nl: 'onze-producenten/jouw-persoonlijk-aanbod',
    fr: 'nos-producteurs/votre-offre-personnelle'
  },
  POSITIVE_ENERGY: {
    nl: 'positieve-energie'
    // no fr
  },
  PRICELISTS: {
    nl: 'prijslijsten',
    fr: 'listes-des-prix'
  },
  PRICING: {
    nl: 'tarief',
    fr: 'tarif'
  },
  PRIVACY: {
    nl: 'disclaimer-privacy-policy',
    fr: 'mentions-legales-politique-de-confidentialite'
  },
  PRODUCERS: {
    nl: 'onze-producenten',
    fr: 'nos-producteurs'
  },
  PRODUCER_INFO: {
    nl: `onze-producenten/${RouteVariables.SLUG}`,
    fr: `nos-producteurs/${RouteVariables.SLUG}`
  },
  PROJECTS: {
    nl: 'projecten',
    fr: 'projets'
  },
  PROJECT_DETAIL: {
    nl: `projecten/${RouteVariables.SLUG}`,
    fr: `projets/${RouteVariables.SLUG}`
  },
  REFERRAL: {
    nl: 'overtuig-een-vriend',
    fr: 'convaincre-un-ami'
  },
  REGISTRATION: {
    nl: `onze-producenten/${RouteVariables.SLUG}/bestel`,
    fr: `nos-producteurs/${RouteVariables.SLUG}/ordre`
  },
  SETTLEMENT: {
    nl: 'afrekening',
    fr: 'regularisation'
  },
  SIMULATION: {
    nl: 'persoonlijk-voorstel',
    fr: 'proposition-personnelle'
  },
  SPRING_CAMPAIGN: {
    nl: 'weetvanwaarjestroomkomt'
    // fr: ''
  },
  TERMS_AND_CONDITIONS: {
    nl: 'algemene-voorwaarden',
    fr: 'termes-et-conditions'
  },
  OLD_TERMS_AND_CONDITIONS: {
    nl: `algemene-voorwaarden/${RouteVariables.VERSION}`,
    fr: `termes-et-conditions/${RouteVariables.VERSION}`
  }
}

// Routes used to generate sitemap
export const STATIC_ROUTES = [
  {
    nl: 'onze-producenten',
    fr: 'nos-producteurs'
  },
  {
    nl: 'nieuws',
    fr: 'actus'
  },
  {
    nl: 'onze-missie',
    fr: 'notre-mission'
  },
  {
    nl: 'prijzen',
    fr: 'prix'
  },
  {
    nl: 'veelgestelde-vragen',
    fr: 'questions-frequentes'
  },
  {
    nl: 'vacatures',
    fr: 'jobs'
  },
  {
    nl: 'overtuig-een-vriend',
    fr: 'convaincre-un-ami'
  },
  {
    nl: 'mini-opwekkers',
    fr: 'mini-producteurs'
  },
  {
    nl: 'projecten',
    fr: 'projets'
  },
  {
    nl: 'projecten/camping-belgica',
    fr: 'projets/camping-belgica'
  },
  {
    nl: 'projecten/rockwerchter2022'
    // fr: 'projets/rockwerchter2022' // not available in FR
  },
  {
    nl: 'projecten/brussels-beer-project',
    fr: 'projets/brussels-beer-project'
  },
  {
    nl: 'projecten/stop-sjoemelstroom-ijsland',
    fr: 'projets/energievertdegris-islande'
  },
  {
    nl: 'projecten/de-wei-dieselvrij',
    fr: 'projets/les-festivals-sans-diesel'
  },
  {
    nl: 'prijslijsten',
    fr: 'listes-des-prix'
  },
  {
    nl: 'afrekening',
    fr: 'regularisation'
  },
  {
    nl: 'algemene-voorwaarden',
    fr: 'termes-et-conditions'
  },
  {
    nl: 'disclaimer-privacy-policy',
    fr: 'mentions-legales-politique-de-confidentialite'
  },
  {
    nl: 'verhuizen',
    fr: 'demenager'
  },
  {
    nl: 'stap-over',
    fr: 'enregistrer-maintenant'
  },
  {
    nl: 'bolt-go',
    fr: 'bolt-go'
  }
]
