import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialCustomerState } from 'store/customer/initial'
import { getNextStep, getPreviousStep, getSpreadedFlowsWithNewValues } from 'store/customer/utils'
import { CustomerFlows, InputGroupKeys, SimulationFieldKeys } from 'store/customer/enums'
import {
  GoToStepPayload,
  NextStepPayload,
  PreviousStepPayload,
  SelectPropositionPayload,
  UpdateAllInputsPayload,
  UpdateBoltGoPricesPayload,
  UpdateFixedPricesPayload,
  UpdateInputPayload,
  UpdatePropositionsPayload
} from 'store/customer/types'
import { runSimulation } from 'store/customer/thunks'
import { Product } from 'types/product-data'
import { SimulationType } from 'store/marketing/types'

export const CustomerSlice = createSlice({
  initialState: initialCustomerState,
  name: 'customer',
  reducers: {
    goToStep: (state, { payload }: PayloadAction<GoToStepPayload>) => {
      const { flow, step } = payload

      state.flows = getSpreadedFlowsWithNewValues(state.flows, flow, {
        currentStep: step
      })
    },
    nextStep: (state, { payload }: PayloadAction<NextStepPayload>) => {
      const { flow } = payload

      const { currentStep, steps } = state.flows[flow]
      state.flows = getSpreadedFlowsWithNewValues(state.flows, flow, {
        currentStep: getNextStep(steps, currentStep)
      })
    },
    previousStep: (state, { payload }: PayloadAction<PreviousStepPayload>) => {
      const { flow } = payload

      const { currentStep, steps } = state.flows[flow]
      state.flows = getSpreadedFlowsWithNewValues(state.flows, flow, {
        currentStep: getPreviousStep(steps, currentStep)
      })
    },
    resetCustomerStore: (_, { payload }: PayloadAction<SimulationType>) => {
      return {
        ...initialCustomerState,
        inputs: {
          ...initialCustomerState.inputs,
          [InputGroupKeys.SIMULATION]: {
            ...initialCustomerState.inputs[InputGroupKeys.SIMULATION],
            [SimulationFieldKeys.CHOSEN_SIMULATION_TYPE]: payload
          }
        }
      }
    },
    selectProposition: (state, { payload }: PayloadAction<SelectPropositionPayload>) => {
      const { productType } = payload
      state.propositions.selectedProposition = productType
    },
    setDidSimulation: (state) => {
      state.flows = getSpreadedFlowsWithNewValues(state.flows, CustomerFlows.SIMULATION, {
        didSimulation: true
      })
    },
    setProductForSpecificSimulation: (state, { payload }: PayloadAction<Product>) => {
      state.propositions.simulateSpecificProduct = payload
    },
    toggleContactMe: (state, { payload }: PayloadAction<Product | undefined>) => {
      state.flows = getSpreadedFlowsWithNewValues(state.flows, CustomerFlows.SIMULATION, {
        contactMe: payload
      })
    },
    updateAllInputs: (state, { payload }: PayloadAction<UpdateAllInputsPayload>) => {
      state.inputs = payload.inputs
    },
    updateBoltGoPrices: (state, { payload }: PayloadAction<UpdateBoltGoPricesPayload>) => {
      state.boltGoPrices = payload
    },
    updateFixedPrices: (state, { payload }: PayloadAction<UpdateFixedPricesPayload>) => {
      state.fixedPrices = payload
    },
    updateInput: (state, { payload }: PayloadAction<UpdateInputPayload>) => {
      const { group, key, value } = payload
      state.inputs[group][key] = value
    },
    updateSimulatedPropositions: (state, { payload }: PayloadAction<UpdatePropositionsPayload>) => {
      const { propositions } = payload
      state.propositions.simulatedPropositions = propositions
    }
  },
  extraReducers: (builder) => {
    // PENDING THUNKS
    builder.addCase(runSimulation.pending, (state) => {
      state.flows = getSpreadedFlowsWithNewValues(state.flows, CustomerFlows.SIMULATION, {
        error: false,
        isLoading: true
      })
    })

    // SUCCESSFUL THUNKS
    builder.addCase(runSimulation.fulfilled, (state, { payload: propositions }) => {
      state.flows = getSpreadedFlowsWithNewValues(state.flows, CustomerFlows.SIMULATION, {
        error: false,
        isLoading: false
      })
      state.propositions.simulatedPropositions = propositions
    })

    // REJECTED THUNKS
    builder.addCase(runSimulation.rejected, (state) => {
      state.flows = getSpreadedFlowsWithNewValues(state.flows, CustomerFlows.SIMULATION, {
        error: true,
        isLoading: false
      })
    })
  }
})

export const {
  goToStep,
  nextStep,
  previousStep,
  resetCustomerStore,
  selectProposition,
  setDidSimulation,
  toggleContactMe,
  updateAllInputs,
  updateBoltGoPrices,
  updateFixedPrices,
  updateInput,
  updateSimulatedPropositions,
  setProductForSpecificSimulation
} = CustomerSlice.actions

export default CustomerSlice.reducer
