import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UpdateMarketingDataPayload, UpdateMarketingStorePayload } from './types'
import { initialMarketingState } from 'store/marketing/initial'

export const MarketingSlice = createSlice({
  initialState: initialMarketingState,
  name: 'marketing',
  reducers: {
    updateMarketingData: (state, action: PayloadAction<UpdateMarketingDataPayload>) => {
      const { key, value } = action.payload
      return {
        ...state,
        [key]: value
      }
    },
    updateMarketingStore: (_state, action: PayloadAction<UpdateMarketingStorePayload>) => {
      const { marketingStore } = action.payload
      return marketingStore
    }
  }
})

export const { updateMarketingData, updateMarketingStore } = MarketingSlice.actions

export default MarketingSlice.reducer
