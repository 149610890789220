import { CounterTypes, KvaInjectionPayload, MeterType, MeterUsage, PropositionRequestOptions, SimulatePriceRequest } from './types'
import { RootState, store } from 'store'
import { InputGroupKeys, MeterDetailsFieldKeys, SimulationFieldKeys } from 'store/customer/enums'
import { ELProduct, NGProduct, Product } from 'types/product-data'
import { Region } from 'types/region'
import { ClientType } from 'types/customer'
import { EnergyUsageGrade, EstimatedUsage } from 'types/usages'
import { UsageStepFormInputs } from './steps/types'
import { checkIsEavOrFixed } from 'utils/customerFlow'
import { ESTIMATED_USAGES } from 'constants/usages'
import { getTotalSimulatedPrice } from 'utils/price'
import { updateInput } from 'store/customer/slice'
import { runSimulation } from 'store/customer/thunks'
import { SimulationType } from 'store/marketing/types'

/**
 * Helper function to check if the usage fields should contain injection fields
 *
 * @param {KvaInjectionPayload} payload
 * @returns {boolean}
 */
export const checkHasInjectionUsageFields = (payload?: KvaInjectionPayload): boolean => {
  const { meterDetails } = (store.getState() as RootState).customer.inputs

  const hasSolarPanels = payload ? payload.hasSolarPanels : meterDetails[MeterDetailsFieldKeys.HAS_SOLAR_PANELS]
  const isAnalogMeter = payload
    ? payload.counterType === CounterTypes.ANALOG
    : meterDetails[MeterDetailsFieldKeys.COUNTER_TYPE] === CounterTypes.ANALOG

  return hasSolarPanels && !isAnalogMeter
}

/**
 * Helper function to check if the usage fields should contain solar kVA fields
 *
 * @param {KvaInjectionPayload} payload
 * @returns {boolean}
 */
export const checkHasSolarKva = (payload?: KvaInjectionPayload): boolean => {
  const { simulation, meterDetails } = (store.getState() as RootState).customer.inputs

  const region = simulation[SimulationFieldKeys.REGION]
  const hasSolarPanels = payload ? payload.hasSolarPanels : meterDetails[MeterDetailsFieldKeys.HAS_SOLAR_PANELS]
  const isAnalog = payload
    ? payload.counterType === CounterTypes.ANALOG
    : meterDetails[MeterDetailsFieldKeys.COUNTER_TYPE] === CounterTypes.ANALOG
  const walloniaSolarPanelsSince2024 = payload
    ? payload.walloniaSolarPanelsSince2024
    : meterDetails[MeterDetailsFieldKeys.WALLONIA_SOLAR_PANELS_SINCE_2024]

  return (
    hasSolarPanels &&
    ((region === Region.WALLONIA && (isAnalog || (!walloniaSolarPanelsSince2024 && !isAnalog))) || (region === Region.FLANDERS && isAnalog))
  )
}

/**
 * Helper function to check if the user has a reversing counter
 *
 * @param {KvaInjectionPayload} payload
 * @returns {boolean}
 */
export const checkHasReversingCounter = (payload?: KvaInjectionPayload): boolean => {
  const { simulation, meterDetails } = (store.getState() as RootState).customer.inputs

  const region = simulation[SimulationFieldKeys.REGION]
  const hasSolarPanels = payload ? payload.hasSolarPanels : meterDetails[MeterDetailsFieldKeys.HAS_SOLAR_PANELS]
  const isAnalog = payload
    ? payload.counterType === CounterTypes.ANALOG
    : meterDetails[MeterDetailsFieldKeys.COUNTER_TYPE] === CounterTypes.ANALOG

  return hasSolarPanels && isAnalog && (region === Region.WALLONIA || region === Region.FLANDERS)
}

/**
 * Helper function to determine the solar kva power
 * @param {UsageStepFormInputs} data
 * @returns {number | null}
 */
export const determineSolarKva = (data: UsageStepFormInputs): number | null => {
  const { isCompany } = (store.getState() as RootState).customer.inputs[InputGroupKeys.PERSONAL_DATA]

  const { counterType, hasSolarPanels, usageGrade, solarKva, walloniaSolarPanelsSince2024 } = data
  const hasSolarKva = checkHasSolarKva({ counterType, hasSolarPanels, walloniaSolarPanelsSince2024 })
  const clientType = isCompany ? ClientType.PROFESSIONAL : ClientType.RESIDENTIAL

  if (hasSolarKva) {
    return (usageGrade !== EnergyUsageGrade.CUSTOM ? ESTIMATED_USAGES[usageGrade][clientType].electricity.kva : solarKva) ?? 0
  }

  return null
}

/**
 * Helper function to determine what value should be used
 * @param {number} dataValue
 * @param {number} estimatedValue
 * @param {EnergyUsageGrade} grade
 * @param {boolean} isNull
 * @returns {number|null}
 */
const determineValue = (dataValue: number, estimatedValue: number, grade: EnergyUsageGrade, isNull?: boolean): null | number => {
  if (isNull) return null
  return grade !== EnergyUsageGrade.CUSTOM ? Math.round(estimatedValue) : Math.round(dataValue)
}

/**
 * Determines & returns the meter usage
 *
 * @param {UsageStepFormInputs} data
 * @returns {MeterUsage}
 */
export const determineSimulationUsage = (data?: UsageStepFormInputs): MeterUsage | undefined => {
  // Fetch data from store
  const { simulation, personalData, meterDetails } = (store.getState() as RootState).customer.inputs

  // deconstruct store data
  const { meterType, usageGrade, usage, hasExclusiveNightMeter } = simulation
  const { isCompany, needsGas } = personalData
  const { counterType, hasSolarPanels } = meterDetails

  // Needed constants
  const grade = data?.usageGrade || usageGrade
  const isSingle = (data?.meterType ?? meterType) === MeterType.SINGLE_RATE
  const isBoltGoRegistration = simulation.chosenSimulationType === SimulationType.BOLT_GO
  const isEavOrFixed = checkIsEavOrFixed(simulation.chosenSimulationType)
  const noUsageData = isBoltGoRegistration || isEavOrFixed
  const usageData = data ?? usage
  const estimatedUsage: EstimatedUsage | null =
    grade !== EnergyUsageGrade.CUSTOM ? ESTIMATED_USAGES[grade][!isCompany ? ClientType.RESIDENTIAL : ClientType.PROFESSIONAL] : null

  const hasInjectionUsageFields = checkHasInjectionUsageFields({
    counterType: data ? data.counterType : counterType,
    hasSolarPanels: data ? !!data.hasSolarPanels : hasSolarPanels
  })
  const hasReversingCounter = checkHasReversingCounter({
    counterType: data ? data.counterType : counterType,
    hasSolarPanels: data ? !!data.hasSolarPanels : hasSolarPanels
  })

  // Return undefined if the grade is custom but no usage data is provided
  if (grade === EnergyUsageGrade.CUSTOM && !usageData) return undefined

  return {
    electricity: {
      consumption: {
        ...(isSingle
          ? {
              single: determineValue(
                Number(usageData?.electricity?.consumption?.single ?? 0),
                hasReversingCounter ? 0 : estimatedUsage?.electricity.consumption,
                grade,
                noUsageData
              )
            }
          : {
              double: {
                day: determineValue(
                  Number(usageData?.electricity?.consumption?.double?.day ?? 0),
                  hasReversingCounter ? 0 : estimatedUsage?.electricity.consumption / 1.5,
                  grade,
                  noUsageData
                ),
                night: determineValue(
                  Number(usageData?.electricity?.consumption?.double?.night ?? 0),
                  hasReversingCounter ? 0 : estimatedUsage?.electricity.consumption / 3,
                  grade,
                  noUsageData
                )
              }
            }),
        ...((data ? data?.hasExclNight : hasExclusiveNightMeter) && {
          exclusiveNight: determineValue(Number(usageData?.electricity?.consumption?.exclusiveNight ?? 0), 0, grade, noUsageData)
        })
      },
      ...(hasInjectionUsageFields && {
        injection: {
          ...(isSingle
            ? {
                single: determineValue(
                  Number(usageData?.electricity?.injection?.single ?? 0),
                  estimatedUsage?.electricity.injection,
                  grade,
                  noUsageData
                )
              }
            : {
                double: {
                  day: determineValue(
                    Number(usageData?.electricity?.injection?.double?.day ?? 0),
                    estimatedUsage?.electricity.injection / 1.5,
                    grade,
                    noUsageData
                  ),
                  night: determineValue(
                    Number(usageData?.electricity?.injection?.double?.night ?? 0),
                    estimatedUsage?.electricity.injection / 3,
                    grade,
                    noUsageData
                  )
                }
              })
        }
      })
    },
    ...((data ? data.needsGas : needsGas) && {
      gas: determineValue(Number(usageData?.gas ?? 0), estimatedUsage?.gas, grade, noUsageData)
    })
  }
}

/**
 * Maps the SimulatePriceRequest data for fetching the price from the API
 *
 * @param {Product} product
 * @returns {SimulatePriceRequest}
 */
export const mapSimulatePriceRequest = (product: Product): SimulatePriceRequest => {
  // Fetch data from store
  const { meterDetails, personalData, simulation } = (store.getState() as RootState).customer.inputs

  // Constants
  const hasSolarKva = checkHasSolarKva()

  return {
    counterType: meterDetails.counterType,
    deliveryPoint: {
      postalCode: personalData.deliveryAddress.postalCode !== '' ? personalData.deliveryAddress.postalCode.toString() : '1000', // Default Bruxelles if no postal code set
      townCode: personalData.deliveryAddress.townCode.toString(),
      townName: personalData.deliveryAddress.townName
    },
    discountCode: simulation.promoCode,
    hasSolarPanels: meterDetails.hasSolarPanels,
    isCompany: personalData.isCompany,
    meterUsage: simulation.usage,
    ...(meterDetails.hasSolarPanels &&
      hasSolarKva && {
        solarKva: simulation.solarKva
      }),
    product: {
      electricity: ELProduct[product as string],
      gas: NGProduct[product as string]
    },
    walloniaSolarPanelsSince2024: meterDetails.walloniaSolarPanelsSince2024
  }
}

/**
 * Requests and saves the proposition for the given products
 *
 * @param {PropositionRequestOptions} options
 */
export const requestAndSavePropositions = async (options?: PropositionRequestOptions): Promise<void> => {
  try {
    const updatedPropositions = await store.dispatch(runSimulation({ products: options?.products })).unwrap()

    if (!Object.keys(updatedPropositions).length) {
      // dispatch error when no propositions are found
      if (options?.onError) options.onError()
    } else {
      // Check if forced amount is still valid & clear if necessary
      const total = getTotalSimulatedPrice(undefined, updatedPropositions)
      if ((store.getState() as RootState).customer.inputs.simulation.forcedAmount < total) {
        store.dispatch(updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.FORCED_AMOUNT, value: undefined }))
      }

      if (options?.onSuccess) options.onSuccess(updatedPropositions)
    }
  } catch (e) {
    if (options?.onError) options.onError()
  }
}
