export enum UrlSearchParams {
  ASK_AGENT = 'ask_agent',
  DP_STREET = 'dpstreet',
  DP_NUMBER = 'dpnumber',
  DP_BOX = 'dpbox',
  DP_ZIP = 'dpzip',
  DP_CITY = 'dpcity',
  DP_EAN_ELECTRICITY = 'dpeanel',
  DP_EAN_GAS = 'dpeanng',
  SALESFORCE_USER_ALIAS = 'sfu',
  SIMULATION_SALES_OFFICE = 'simulation_so',
  REFERRAL_CODE = 'referralcode'
}
