import { PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SessionStorageKeys } from 'constants/constants'
import { updateMarketingStore } from 'store/marketing/slice'

const StoreInitializer = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (window.sessionStorage) {
      const serializedStore = window.sessionStorage.getItem(SessionStorageKeys.MARKETING)

      if (serializedStore) {
        try {
          dispatch(updateMarketingStore({ marketingStore: JSON.parse(serializedStore) }))
        } catch (error) {
          window.sessionStorage.removeItem(SessionStorageKeys.MARKETING)
        }
      }
    }
  }, [dispatch])

  return children
}

export default StoreInitializer
