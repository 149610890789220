export type BannerProps = {
  onClickHandler: () => void
  disableToggleOnScroll?: boolean
  className?: string
}

export enum BannerTypes {
  BRUSSELS_BEER_PROJECT = 'brusselsBeerProject',
  VIA_VIA = 'copy',
  SIMULATION_DOWN = 'simulationDown'
}

export type PredefinedBanner = {
  ns: string
  main: {
    defaultCopy: string
    i18nKey: string
  }
  small?: {
    defaultCopy: string
    i18nKey: string
  }
  color: string
}
